<template>
  <div class="gallery-folder-page">
    <v-row justify="center">
      <v-col md="8">
        <intract-smart-list
          class="mt-2 mx-2"
          paginated
          :endpoint="galleryFolderEndpoint"
          disable-search
          @updateAllItemsList="(l) => (galleryPosts = l)"
          ref="gallery-folder-smart-list"
        >
          <template v-slot:list="{ itemsList: posts }">
            <v-row class="mt-2 mb-2" dense>
              <v-col cols="4" md="3" v-for="post in posts" :key="post.id">
                <v-img
                  class="cursor-pointer"
                  v-if="post.image_file"
                  :src="post.image_file.document"
                  aspect-ratio="1"
                  @click="
                    $router.push({
                      name: 'GalleryPost',
                      params: { postId: post.id },
                    })
                  "
                >
                </v-img>
                <div class="video-container" v-else-if="post.video_file">
                  <v-img
                    class="cursor-pointer"
                    src="https://www.pngkit.com/png/full/267-2678423_bacteria-video-thumbnail-default.png"
                    aspect-ratio="1"
                    cover
                    @click="
                      $router.push({
                        name: 'GalleryPost',
                        params: { postId: post.id },
                      })
                    "
                    ><div class="image-icon">
                      <v-btn icon
                        ><v-icon size="80px" color="white"
                          >mdi-play</v-icon
                        ></v-btn
                      >
                    </div>
                  </v-img>
                </div>
                <div class="video-container" v-else-if="post.video_link">
                  <v-img
                    class="cursor-pointer"
                    :src="Helper.getYouTubeThumbnailURL(post.video_link)"
                    aspect-ratio="1"
                    cover
                    @click="
                      $router.push({
                        name: 'GalleryPost',
                        params: { postId: post.id },
                      })
                    "
                    ><div class="image-icon">
                      <v-btn icon
                        ><v-icon size="80px" color="white"
                          >mdi-play</v-icon
                        ></v-btn
                      >
                    </div>
                  </v-img>
                </div>
                <div
                  class="folder-container fill-height"
                  v-else-if="post.is_folder"
                >
                  <v-card
                    class="fill-height py-0"
                    @click="
                      $router.push({
                        name: 'GalleryFolder',
                        params: { folderId: post.id },
                      })
                    "
                  >
                    <v-card-text
                      class="
                        py-0
                        d-flex
                        justify-center
                        align-center
                        fill-height
                      "
                    >
                      <div class="text-center">
                        <v-icon size="5em">mdi-folder-multiple-image</v-icon>
                        <p class="ma-0 mt-1 font-weight-medium folder-title">
                          {{ post.caption }}
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- creation options -->
    <intract-list-sheet
      title="What do you want to post?"
      :visible="showPostOptions"
      :list="postOptions"
      @close="showPostOptions = false"
    />
    <!-- create image post -->
    <intract-create-edit-sheet
      persistent
      key="gallery-create-image-sheet"
      class="gallery-create-image-sheet"
      title="Create Image Post"
      description="Upload image(s) and display in gallery"
      :visible="createImagePostSheet.visible"
      @close="
        createImagePostSheet.visible = false;
        createImagePostSheet.editId = null;
      "
      :fields="createImagePostFormFields"
      :file-size-limit="5242880"
      :endpoint="endpoints.galleryPostViewSet"
      :custom-error-text="customImagePostValidation"
      :edit-id="createImagePostSheet.editId"
      :data-object="createImagePostSheet.obj"
      :create-success-message="createImagePostSheet.createSuccessMessage"
      enable-media
      @objectCreated="imageCreated"
      @updateObject="(obj) => (createImagePostSheet.obj = obj)"
    />
    <!-- create video post -->
    <intract-create-edit-sheet
      persistent
      key="gallery-create-video-sheet"
      class="gallery-create-video-sheet"
      title="Create Video Post"
      description="Upload video content to the gallery"
      :visible="createVideoPostSheet.visible"
      @close="
        createVideoPostSheet.visible = false;
        createVideoPostSheet.editId = null;
      "
      :fields="createVideoPostFormFields"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="createVideoPostSheet.editId"
      :data-object="createVideoPostSheet.obj"
      :custom-error-text="customVideoPostValidationError"
      :create-success-message="createVideoPostSheet.createSuccessMessage"
      enable-media
      @objectCreated="imageCreated"
      @updateObject="(obj) => (createVideoPostSheet.obj = obj)"
    />
    <!-- create folder -->
    <intract-create-edit-sheet
      persistent
      key="gallery-edit-folder-sheet"
      class="gallery-edit-folder-sheet"
      title="Edit Folder"
      description="Use folders to better organise your gallery"
      :visible="editFolderSheet.visible"
      @close="
        editFolderSheet.visible = false;
        editFolderSheet.editId = null;
      "
      :fields="editFolderFormSheet"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="editFolderSheet.editId"
      :data-object="editFolderSheet.obj"
      :edit-success-message="editFolderSheet.editSuccessMessage"
      @objectCreated="folderEdited"
      @updateObject="(obj) => (editFolderSheet.obj = obj)"
    />
     <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this gallery folder?"
      description="This will also delete all the images and videos residing inside this folder. This action cannot be undone."
      @successCallback="deleteGalleryFolder"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- create gallery post button -->
    <v-fab-transition>
      <v-btn
        color="primary"
        v-if="!isLoading && currentUser.is_admin"
        @click="showPostOptions = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractListSheet from "@components/generics/IntractListSheet";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import EventBus from "@utils/event_bus";
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';
 
export default {
  name: "GalleryFolder",
  mixins: [
    Mixins.essentials,
    Mixins.handleAppBarTitle,
    Mixins.handleAppBarOptions,
  ],
  components: {
    IntractSmartList,
    IntractListSheet,
    IntractCreateEditSheet,
    ConfirmationDialog,
  },
  data() {
    return {
      showPostOptions: false,
      galleryPosts: [],
      folder: null,
      showDeleteDialog:false,
      postOptions: [
        {
          icon: "mdi-image",
          title: "Image(s)",
          subtitle: "Upload image(s) and display in gallery",
          action: () => (this.createImagePostSheet.visible = true),
        },
        {
          icon: "mdi-video",
          title: "Video(s)",
          subtitle: "Upload video content to the gallery",
          action: () => (this.createVideoPostSheet.visible = true),
        },
        // {
        //   icon: "mdi-folder-multiple-image",
        //   title: "Folder",
        //   subtitle: "Create a folder to better organise your gallery",
        //   action: () => (this.editFolderSheet.visible = true),
        // },
      ],

      createImagePostSheet: {
        visible: false,
        createSuccessMessage: "Image(s) uploaded successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: false,
          parent: Number(this.$route.params.folderId),
        },
      },
      createVideoPostSheet: {
        visible: false,
        createSuccessMessage: "Video(s) uploaded successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: false,
          parent: Number(this.$route.params.folderId),
        },
      },
      editFolderSheet: {
        visible: false,
        editSuccessMessage: "Folder edited successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: true,
        },
      },
    };
  },
  computed: {
    appBarTitle() {
      if (!this.folder) return "";
      return this.folder.caption;
    },
    galleryFolderEndpoint() {
      return this.Helper.addUrlParams(
        this.endpoints.galleryPostViewSet,
        "parent=" + (this.folder? this.folder.id : this.$route.params.folderId)
      );
    },
    appBarOptions() {
      if (!this.currentUser.is_admin) return { actions: [] };

      return {
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.editFolderSheet.editId = Number(this.$route.params.folderId);
              this.editFolderSheet.visible = true;
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => {
              this.showDeleteDialog = true;
              // this.introSheet.editId = Number(this.$route.params.introId);
            },
          },
        ],
      };
    },
    customImagePostValidation() {
      
      if (!this.createImagePostSheet.obj.images_list || this.createImagePostSheet.obj.images_list.length <=10) return null;
      return "A maximum of 10 images can be uploaded!"
    },
    createImagePostFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Caption",
          required: false,
          md: 12,
          max: 500,
        },
        images_list: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Image(s)",
          helper: "JPG, PNG, GIF, etc",
          valid: () => this.createImagePostSheet.obj.images_list && this.createImagePostSheet.obj.images_list.length,
        },
      };
    },
    videosAndLinkPresent(){
      var videos = this.createVideoPostSheet.obj.videos_list != null && this.createVideoPostSheet.obj.videos_list.length > 0;
      var link = this.createVideoPostSheet.obj.video_link != null;
      return {videos, link};
    },
    customVideoPostValidationError(){
      if (this.videosAndLinkPresent.videos && this.videosAndLinkPresent.link) 
        return "Video Link and Video File(s) should be uploaded separately!";
      else if (this.createVideoPostSheet.obj.videos_list && this.createVideoPostSheet.obj.videos_list.length > 5)
        return "A maximum of 5 videos can be uploaded!"
      else 
        return null;
    },
    videoPostValidation(){
      return this.videosAndLinkPresent.videos != this.videosAndLinkPresent.link;
    },
    createVideoPostFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Caption",
          required: false,
          md: 12,
          max: 500,
        },
        video_link: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: false,
          label: "Link a video",
          helper: "Link a YouTube Video",
          valid: () => this.videoPostValidation,
        },
        videos_list: {
          fieldType: CreateEditFieldTypes.VIDEOUPLOAD,
          multiple: true,
          label: "Upload Video(s)",
          helper: "Upload video(s) from this device",
          valid: () => this.videoPostValidation,
        },
      };
    },
    editFolderFormSheet() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Folder Title",
          required: true,
          counter: 40,
          max: 40,
          md: 12,
        },
      };
    },
  },
  methods: {
    async imageCreated(image) {
      this.onRefresh();
      this.$router.push({ name: "GalleryPost", params: { postId: image.id } });
    },
    async folderEdited(folder) {
      this.folder = folder;
      EventBus.$emit('gallery__post_updated', folder);
    },
    async getFolderDetails() {
      var url =
        this.endpoints.galleryPostViewSet + this.$route.params.folderId + "/";
      this.folder = await this.api.call(this.essentials, url);
    },
    async deleteGalleryFolder() {
      var url = this.endpoints.galleryPostViewSet + this.$route.params.folderId + '/';
      var response = await this.api.call(this.essentials, url, this.api.Methods.DELETE);
      if(response){
        this.showSnackbar({title: 'Gallery folder has been successfully deleted!', type: 'success'});
        EventBus.$emit('gallery__post_deleted', this.$route.params.folderId);
        this.$router.back();
      }
    },
    setListeners() {
      var self = this;
      EventBus.$on("gallery__gallery_folder__post_deleted", (itemId) => {
        if (self.$refs["gallery-folder-smart-list"])
          self.$refs["gallery-folder-smart-list"].removeItem(itemId);
      });
      EventBus.$on("gallery__gallery_folder__post_updated", (item) => {
        if (self.$refs["gallery-folder-smart-list"])
          self.$refs["gallery-folder-smart-list"].replaceItem(item.id, item);
      });
    },
    async onRefresh() {
      if (this.$refs["gallery-folder-smart-list"]) {
        this.$refs["gallery-folder-smart-list"].getItems();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Gallery") {
      EventBus.$emit("keep_alive__destroy_view", "GalleryFolder");
    }
    next();
  },
  created() {
    this.getFolderDetails();
    this.setListeners();
    this.createImagePostSheet.obj.created_by =
      this.createVideoPostSheet.obj.created_by =
      this.editFolderSheet.obj.created_by =
        this.currentUser.id;
    this.createImagePostSheet.obj.institution =
      this.createVideoPostSheet.obj.institution =
      this.editFolderSheet.obj.institution =
        this.currentInstitution.id;
  },
};
</script>

<style lang="sass" scoped>
.image-icon
    height: 100%
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.folder-title
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    max-width: 9em
</style>